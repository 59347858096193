<template>
  <v-app>
    <v-container class="my-6 mx-0" fluid>
      <v-card class="px-4 py-5" style="background-color: transparent">
        <h2 class="text-typo">Санал асуулга</h2>
        <v-row class="mx-1">
          <v-spacer></v-spacer>
          <v-btn
            class="bg-gradient-primary"
            dark
            @click="_editAsuulga({})"
            v-if="
              userData.role == 'admin' ||
              userData.role == 'schoolmanager' ||
              userData.role == 'superadmin' ||
              userData.role == 'schoolsocialworker'
            "
            >Асуулга+
          </v-btn>
        </v-row>
        <v-btn
          elevation="0"
          class="text-capitalize my-5 ml-1"
          dark
          color="bg-gradient-danger"
          v-if="userData.role == 'teacher'"
          @click.stop="_seeTeacherResults()"
        >
          Миний үр дүн
        </v-btn>
        <v-row class="my-10" v-if="asuulguud != null && asuulguud.length > 0">
          <v-card
            class="card-shadow border-radius-xl pt-2 pb-6 mx-4"
            v-for="(asuulga, nindex) in asuulguud"
            v-bind:key="nindex"
            width="400"
            @click="_detailAsuulga(asuulga)"
          >
            <v-row class="px-4 pt-4">
              <v-col>
                <h6 class="text-h6 text-typo font-weight-bold">
                  {{ asuulga.title }}
                </h6>
              </v-col>
              <v-col md="2" lg="2" cols="2" class="text-end py-0">
                <v-menu
                  transition="slide-y-transition"
                  offset-y
                  offset-x
                  min-width="150"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      :ripple="false"
                      class="text-secondary ms-auto mt-3"
                      v-bind="attrs"
                      v-on="on"
                      small
                    >
                      <v-icon size="16">fas fa-ellipsis-v</v-icon>
                    </v-btn>
                  </template>

                  <v-list class="py-0">
                    <v-list-item
                      v-if="_isAllowed(asuulga)"
                      class="list-item-hover-active"
                      @click.stop="_editAsuulga(asuulga)"
                    >
                      <v-list-item-content class="pa-0">
                        <v-list-item-title> Засаx </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                      v-if="_isAllowed(asuulga)"
                      class="list-item-hover-active"
                      @click.stop="_makePublicAsuulga(asuulga)"
                    >
                      <v-list-item-content class="pa-0">
                        <v-list-item-title class="red--text font-weight-bold">
                          <span v-if="asuulga.public">Xаалттай болгоx</span>
                          <span v-else>Нээлттэй болгоx</span>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                      v-if="_isAllowed(asuulga)"
                      class="list-item-hover-active"
                      @click.stop="_deleteAsuulga(asuulga)"
                    >
                      <v-list-item-content class="pa-0">
                        <v-list-item-title
                          class="ls-0 text-body font-weight-600 mb-0"
                        >
                          Устгаx
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <hr class="horizontal dark" />

                    <v-list-item
                      class="list-item-hover-active"
                      @click.stop="_tryAsuulga(assignment)"
                    >
                      <v-list-item-content class="pa-0">
                        <v-list-item-title
                          class="ls-0 text-body font-weight-600 mb-0"
                        >
                          Туршиx
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>
            <v-card-text>
              <span class="primary--text">{{ asuulga.createdByName }}</span>
            </v-card-text>
            <v-card-text class="py-0 my-0">
              {{ asuulga.description }}
            </v-card-text>
            <v-card-text>
              <v-chip
                small
                v-if="asuulga.forAllTeacher"
                style="background-color: #fff8c5"
                >Багш</v-chip
              >
              <v-chip
                small
                v-if="asuulga.forParent"
                class="mx-1"
                style="background-color: #fff8c5"
                >Эцэг эx</v-chip
              >
              <v-chip
                small
                v-if="asuulga.forAllStudent"
                class="mx-1"
                style="background-color: #fff8c5"
                >Сурагч</v-chip
              >
            </v-card-text>
            <v-card-actions class="mt-2 px-6">
              <v-spacer></v-spacer>
              <v-btn
                class="border-radius-sm text-xxs text-white shadow-none font-weight-bold btn-primary bg-gradient-primary"
                elevation="0"
                :ripple="false"
                @click.stop="_resultAsuulga(asuulga)"
                >Үр дүн</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-row>
        <v-dialog v-model="schoolModal" width="30%" v-if="selectedAsuulga">
          <v-card class="pa-3" rounded="xl">
            <v-card-title class="px-4 text-h5">Асуулга нэмэх</v-card-title>
            <v-card-actions>
              <v-text-field
                autofocus
                v-model.trim="selectedAsuulga.title"
                dense
                label="Гарчиг"
              ></v-text-field>
            </v-card-actions>
            <v-card-actions>
              <v-textarea
                auto-grow
                v-model.trim="selectedAsuulga.description"
                dense
                label="Тайлбар мэдээлэл"
              ></v-textarea>
            </v-card-actions>
            <v-card-actions>
              <v-text-field
                v-model.trim="selectedAsuulga.videoUrl"
                dense
                label="Линк"
              ></v-text-field>
            </v-card-actions>

            <v-card-actions class="py-0">
              <v-checkbox
                v-model="selectedAsuulga.forAllTeacher"
                label="Багш нарт"
              ></v-checkbox>
              <v-checkbox
                class="ml-4"
                v-model="selectedAsuulga.forAllStudent"
                label="Сурагчдад"
              ></v-checkbox>
              <v-checkbox
                class="ml-4"
                v-model="selectedAsuulga.forParent"
                label="Эцэг эx"
              ></v-checkbox>
            </v-card-actions>
            <v-card-actions class="d-flex flex-row">
              <v-spacer></v-spacer>

              <v-btn text @click="schoolModal = !schoolModal" dark
                >Цуцлах</v-btn
              >
              <v-btn
                class="bg-gradient-primary text-capitalize"
                @click="_saveAsuulga"
                dark
                >Хадгалах</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card>
    </v-container>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import myfunction from "@/components/global/myfunction.js";
const fb = require("@/firebaseConfig.js");

export default {
  data() {
    return {
      asuulguud: null,
      selectedAsuulga: null,

      object: {
        title: null,
        description: null,
        published: false,
      },
      customerData: null,
      loading: false,
      school: null,
      myAsuulguud: null,
      schoolModal: false,
    };
  },
  mixins: [myfunction],
  computed: {
    ...mapState(["userData", "currentUser"]),
  },
  methods: {
    _detailAsuulga(asuulga) {
      this.selectedAsuulga = asuulga;
      this.$router.push({
        name: "AsuulgaEditing",
        params: { asuulgaPath: asuulga.ref.path },
      });
    },
    _resultAsuulga(asuulga) {
      if (!asuulga.notEdited)
        this.$swal.fire("Одоогоор асуулганд үр дүн байxгүй байна!");
    },
    _isAllowed(asuulga) {
      console.log(asuulga);
      return true;
    },
    _editAsuulga(asuulga) {
      this.selectedAsuulga = Object.assign({}, asuulga);
      this.schoolModal = !this.schoolModal;
    },
    _deleteAsuulga(asuulga) {
      console.log(asuulga);
    },
    _tryAsuulga(asuulga) {
      console.log(asuulga);
    },
    _makePublicAsuulga(asuulga) {
      console.log(asuulga);
    },
    _answer(itm) {
      if (this.userData.role == "student")
        this.$router.push({
          name: "AsuulgaCXAnswer",
          params: { sorilId: itm.ref.path },
        });
    },
    _addTeachers(itm) {
      this.$router.push({
        name: "AsuulgaTeachers",
        params: { sorilId: itm.ref.path },
      });
    },
    _seeManagerResults(itm) {
      this.$router.push({
        name: "AsuulgaResultsManager",
        params: { sorilId: itm.ref.path },
      });
    },
    _seeClassResults(itm) {
      this.$router.push({
        name: "AsuulgaResultsClassTeacher",
        params: { sorilId: itm.ref.path },
      });
    },
    _seeTeacherResults() {
      this.$router.push({
        name: "AsuulgaResultsTeacher",
      });
    },
    _seeResults(itm) {
      console.log(itm.ref.path);

      this.$router.push({
        name: "AsuulgaResults",
        params: { sorilId: itm.ref.path },
      });
    },
    _isSubmitted() {
      console.log(this.myAsuulguud);
      if (this.asuulguud != null) {
        for (const asuulga of this.asuulguud) {
          if (this.myAsuulguud != null) {
            for (const myasuulga of this.myAsuulguud) {
              if (asuulga.id == myasuulga.id) return true;
            }
          }
        }
      }
      return false;
    },

    _goDetail(item) {
      // if (item.viewCount) {
      //   fb.db
      //     .collection(this.asuulgaPath)
      //     .doc(item.id)
      //     .update({
      //       viewCount: item.viewCount + 1,
      //     });
      // } else {
      //   fb.db.collection(this.asuulgaPath).doc(item.id).update({
      //     viewCount: 1,
      //   });
      // }
      // console.log(item)
      this.$router.push({
        // name: "XADetail",
        name: "AsuulgaEditing",
        params: { asuulgaPath: item.ref.path },
      });
    },
    _saveAsuulga() {
      if (
        this.selectedAsuulga != null &&
        this.selectedAsuulga.title != null &&
        this.selectedAsuulga.title != ""
      ) {
        // if (
        //   this.selectedAsuulga.videoUrl &&
        //   this.selectedAsuulga.videoUrl.toLowerCase().indexOf("youtube") == -1
        // ) {
        //   alert("Та заавал youtube бичлэг оруулна уу");
        //   return;
        // }
        if (this.selectedAsuulga.ref) {
          this.selectedAsuulga.ref.update(this.selectedAsuulga).then(() => {
            this.selectedAsuulga = null;
            this.schoolModal = !this.schoolModal;
          });
        } else {
          this.selectedAsuulga.createdAt = new Date();
          this.selectedAsuulga.createdByRef = this.userData.ref;
          this.selectedAsuulga.createdByName =
            this.userData.name != null ? this.userData.name : "";

          this.userData.school.ref
            .collection("asuulga")
            .doc()
            .set(this.selectedAsuulga)
            .then(() => {
              this.selectedAsuulga = null;
              this.schoolModal = !this.schoolModal;
            });
        }
      } else {
        alert("Та гарчиг, тайлбараа оруулна уу");
      }
    },

    _deleteNews(asuulga) {
      this.$swal({
        title: "Та " + asuulga.title + " устгах уу?",
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          asuulga.ref.delete();
        }
      });
    },
    _sendNotif(data) {
      if (this.asuulgaPath && data && data.id) {
        var result = confirm("Та " + data.title + " зарлал илгээх үү?");
        if (result) {
          fb.db.collection(this.asuulgaPath).doc(data.id).update({
            notified: !data.notified,
          });
        }
      }
    },
  },
  created() {
    this.userData.school.ref
      .collection("asuulga")
      .where("createdByRef", "==", this.userData.ref)
      .onSnapshot((docs) => {
        this.asuulguud = [];
        docs.forEach((doc) => {
          let asuulga = doc.data();
          asuulga.ref = doc.ref;
          asuulga.id = doc.id;
          this.asuulguud.push(asuulga);
        });
      });
  },

  filters: {
    formatDate(val) {
      if (!val) {
        return "-";
      }
      let date = val.toDate();
      return moment(date).fromNow();
    },
    trimLength(val) {
      if (val.length < 200) {
        return val;
      }
      return `${val.substring(0, 200)}...`;
    },
  },
};
</script>
